import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export default function Banner_recrutamento() {

    return (
        <>
            <Swiper
                className='d-none d-lg-block' fade>
                    <SwiperSlide >
                        <img
                            className="w-100"
                            src='https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/jofran/banner/VAGA%20RECEPCIONISTA%20SITE%20COMPACTA%20.png'
                        />

                    </SwiperSlide>
                   
            </Swiper>
            <Swiper
                className='d-lg-none'>
               
                    <SwiperSlide interval={3000}>
                        <img
                            className="w-100"
                            src='https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/jofran/banner/VAGA%20RECEPCIONISTA%20SITE%20COMPACTA%20.png'
                        />
                    </SwiperSlide>
                  
            </Swiper>

        </>

    );
}
