import Banner_recrutamento from "../../components/Banner/Banner_recrutamento";
import "./recrutamento.css"
export default function Recrutamento() {
    return (
        <>
            <div id="recrutamento">
                <div className="bg-max">
                    <Banner_recrutamento />
                    <div className="text-light p-4">
                        <h2 className="text-center">Descrição da Vaga</h2>
                        <ul className="fw-semibold">
                            <li>Atendimento ao cliente presencial e por telefone</li>
                            <li>Controle de agendamentos e compromissos</li>
                            <li>Gerenciamento de correspondências e documentos</li>
                            <li>Auxílio em tarefas administrativas gerais</li>
                        </ul>
                        <hr className="color-light" />
                        <h2 className="text-center">Requisitos Desejáveis</h2>
                        <ul className="fw-semibold">
                            <li>Experiência anterior em atendimento ao cliente ou função similar</li>
                            <li>Boa comunicação e habilidades interpessoais</li>
                            <li>Organização e capacidade de multitarefa</li>
                            <li>Conhecimento básico em informática</li>
                        </ul>
                        <hr className="color-light" />
                        <h2 className="text-center"> Como enviar se Currículo?</h2>
            
                        <ol>
                            <li>Clique no botão <b>"Enviar Currículo"</b></li>
                            <li>Verifique se no assunto do Email está como <b>"Vaga de Recepcionista".</b></li>
                            <li>E por último, apenas <b>"Anexe seu currículo"</b> como arquivo no email.</li>
                        </ol>
                        <a target="_blank" href="mailto:jofranimoveis.vagas@gmail.com?Subject=Vaga de Recepcionista&body=Segue em anexo o currículo!"> <button className="btn btn-success btn-lg d-flex m-auto fw-bold shadow-lg">Enviar Currículo</button></a>
                        <p className="text-center"><small>jofranimoveis.vagas@gmail.com</small> </p>
                    </div>
                </div>
            </div>


        </>
    )
}