import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export default function Banner3() {

    return (
        <>
            <Swiper
                className='d-none d-lg-block' fade>
                    <SwiperSlide >
                        <img
                            className="w-100"
                            src='https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/jofran/banner/Site/ARTE-1-1200X600.png'
                        />

                    </SwiperSlide>
                   
            </Swiper>
            <Swiper
                className='d-lg-none'>
               
                    <SwiperSlide interval={3000}>
                        <img
                            className="w-100"
                            src='https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/jofran/banner/Site/mobile/SITE-1-MOBILE.png?t=2024-06-20T12%3A42%3A13.900Z'
                        />
                    </SwiperSlide>
                  
            </Swiper>

        </>

    );
}
